import { httpPost } from '@/api';
import { ErpTableOptions } from '@/types/type';
import { erpConfirm, errorTip, successTip } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '客户',
      prop: 'customerName',
      propDesc: 'customerName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '客户认证类型',
      prop: 'authType',
      propDesc: 'authTypeDesc',
      minWidth: 160,
      value: '',
      type: 'signSelect',
      options: 'auth_type',
    },
    {
      label: '电子合同授权状态',
      prop: 'status',
      propDesc: 'statusDesc',
      minWidth: 170,
      value: '',
      type: 'signSelect',
      options: 'auth_status',
    },
    {
      label: '电子合同授权生效时间',
      prop: 'startDate',
      minWidth: 200,
      value: [],
      type: 'time',
      filterProp: ['startDateStartDate', 'startDateEndDate'],
    },
    {
      label: '合同签署人姓名',
      prop: 'legalName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '合同签署人手机号',
      minWidth: 170,
      prop: 'legalMobile',
      value: '',
      type: 'input',
    },
    {
      label: '授权文件',
      prop: 'argentinaIndex',
      propDesc: '',
      minWidth: 115,
      value: '',
      type: 'input',
      isFilter: false,
      isSort: false,
      component: defineComponent({
        template: `
        <TableFileContent
        class="margin-upload"
        :readOnly="true"
        :fileUrlsArray="row.fileVoList&&row.fileVoList.length>0? row.fileVoList:null"
        v-if="row.fileVoList&&row.fileVoList.length>0"
        keyName="fileName"
        keyUrl="fileUrl"
        customClass="list-left"
      ></TableFileContent>
      <div v-else>-</div>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          return {};
        },
      }),
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 170,
      value: [],
      type: 'time',
      filterProp: ['createdTimeStartDate', 'createdTimeEndDate'],
    },
    {
      label: '创建人',
      prop: 'creator',
      propDesc: 'creator',
      minWidth: 160,
      type: 'input',
      value: '',
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 164,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button type="primary" plain style="margin-right: 0;" v-if="hasPermission('eqb:grant:saveEqbElectricContractGrant')" @click='addContract(row)'>编辑
        </el-button>
        <el-button plain v-if="hasPermission('eqb:grant:saveEqbElectricContractGrant')" @click='deleteItem(row)'>删除</el-button>     
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const addContract = (row) => {
            Instance.setupState.getauditDetails(row);
          };
          const deleteItem = (row) => {
            erpConfirm('确定删除该条记录吗？')
              .then(async () => {
                const res = await httpPost('/malicrm/eqb/grant/deleteEqbElectricContractGrant', {
                  id: row.id,
                  version: row.version,
                });
                if (res.code === 200) {
                  successTip('删除成功');
                  Instance.setupState.refreshTable();
                } else {
                  errorTip(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { addContract, deleteItem };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
