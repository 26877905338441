<template>
  <div>
    <div>
      <div class="erp-form__header header-bar__sticky">
        <div class="erp-form__title">{{ type == 1 ? '新增' : '编辑' }}电子合同授权</div>
        <div class="erp-form-bar">
          <div class="erp-primary__btn" @click="submit">保存</div>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <div class="erp-form__body">
        <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData" v-if="load">
          <div class="mali-edit__form">
            <el-row :gutter="16" type="flex">
              <el-col :span="12">
                <el-form-item label="客户" prop="customerId">
                  <el-select
                    :disabled="type == 2"
                    v-model="formData.customerId"
                    clearable
                    class="mali-full__inputcom"
                    filterable
                    placeholder="请选择客户"
                    remote
                    reserve-keyword
                    :remote-method="searchCustomer"
                    @change="changeCustomer"
                    @clear="getCustomerList"
                    suffix-icon="el-icon-arrow-down"
                    style="width: 100%"
                  >
                    <el-option v-for="item in customerOptions" :key="item.id" :label="item.customerName" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 新增客户认证类型 -->
              <el-col :span="12">
                <el-form-item label="客户认证类型" prop="authType">
                  <el-select
                    :disabled="type == 2"
                    v-model="formData.authType"
                    clearable
                    class="mali-full__inputcom"
                    filterable
                    placeholder="请选择客户认证类型"
                    suffix-icon="el-icon-arrow-down"
                    style="width: 100%"
                  >
                    <el-option v-for="item in authTypeOptions" :key="item.authType" :label="item.authTypeDesc" :value="item.authType"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电子合同授权状态" prop="status">
                  <el-select
                    style="width: 100%"
                    v-model="formData.status"
                    clearable
                    placeholder="请选择电子合同授权状态"
                    class="mali-full__inputcom"
                    @change="changeStatus"
                  >
                    <el-option label="已授权" :value="1"></el-option>
                    <el-option label="授权已过期" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电子合同授权生效时间" :prop="!isDisabled ? 'startDate' : null">
                  <el-date-picker
                    v-model="formData.startDate"
                    prefix-icon="null"
                    placeholder="请选择电子合同授权生效时间"
                    type="date"
                    style="width: 100%"
                    :disabled="isDisabled"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="合同签署人姓名" :prop="!isDisabled ? 'legalName' : null">
                  <el-input
                    :maxlength="4"
                    v-model="formData.legalName"
                    clearable
                    placeholder="请输入合同签署人姓名"
                    class="mali-full__inputcom"
                    @blur="changeChinese($event, 'legalName')"
                    :disabled="isDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="合同签署人身份证号" :prop="!isDisabled ? 'legalIdNo' : null">
                  <el-input
                    :maxlength="18"
                    v-model="formData.legalIdNo"
                    clearable
                    placeholder="请输入合同签署人身份证号"
                    class="mali-full__inputcom"
                    @blur="changeIdNo($event, 'legalIdNo')"
                    :disabled="isDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="合同签署人手机号" :prop="!isDisabled ? 'legalMobile' : null">
                  <el-input
                    :maxlength="11"
                    v-model="formData.legalMobile"
                    clearable
                    placeholder="请输入合同签署人手机号"
                    class="mali-full__inputcom"
                    @blur="changePhone($event, 'legalMobile')"
                    :disabled="isDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <div class="erp-form__title f16 after-req" style="margin-bottom: 19px">授权文件</div>
                <el-form-item label prop="fileVoList">
                  <div class="upload-box">
                    <SingleFileContent
                      :fromType="type"
                      fileType="noZip"
                      :max="5"
                      :fileList="fileUrlsArray"
                      @success="getFileUrls"
                      style="margin-left: 6px"
                    ></SingleFileContent>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import { formatTime } from '@/utils/publicMethods';
export default {
  props: {
    type: { type: [String, Number] },
    baseData: { type: [Object] }, // 基础信息
    version: { type: [String, Number] },
    data: {
      type: [Object, Array],
    },
  },
  components: {
    SingleFileContent,
  },
  data() {
    return {
      fileUrlsArray: [],
      rules: {
        // 检验规则
        legalIdNo: [
          {
            required: true,
            message: '请输入合同签署人身份证号',
            trigger: 'blur',
          },
        ],
        customerId: [
          {
            required: true,
            message: '请选择客户',
            trigger: 'change',
          },
        ],
        authType: [
          {
            required: true,
            message: '请选择客户认证类型',
            trigger: 'change',
          },
        ],
        startDate: [
          {
            required: true,
            message: '请选择电子合同授权生效时间',
            trigger: 'blur',
          },
        ],
        status: [
          {
            required: true,
            message: '请选择电子合同授权状态',
            trigger: 'change',
          },
        ],
        legalName: [
          {
            required: true,
            message: '请输入合同签署人姓名',
            trigger: 'blur',
          },
        ],
        legalMobile: [
          {
            required: true,
            message: '请输入合同签署人手机号',
            trigger: 'blur',
          },
          { len:11,message: '合同签署人手机号必须为11位', trigger: 'blur' },
        ],
        fileVoList: [
          {
            required: true,
            message: '请上传授权文件',
            trigger: 'change',
          },
        ],
      },
      formData: {
        customerId: null,
        customerName: '',
        authType: null,
        fileVoList: [],
        legalIdNo: null,
        id: null,
        legalMobile: '',
        legalName: '',
        startDate: null,
        status: null,
        version: null,
      },
      customerOptions: [],
      authTypeOptions: [
        { authTypeDesc: '个人', authType: 1 },
        { authTypeDesc: '企业', authType: 2 },
      ],
      isDisabled: false,
      load: true,
    };
  },
  created() {
    if (this.type === 2) {
      this.formData = JSON.parse(JSON.stringify(this.baseData));
      const files = JSON.parse(JSON.stringify(this.baseData.fileVoList));
      const arr = [];
      files.forEach((v) => {
        const file = {
          name: v.fileName,
          url: v.fileUrl,
        };
        arr.push(file);
      });
      this.fileUrlsArray = arr;
      this.isDisabled = this.formData.status === 2;
      if (this.formData.status === 2) {
        this.formData.legalMobile = null;
        this.formData.legalName = null;
        this.formData.startDate = null;
        this.formData.legalIdNo = null;
      }
      this.searchCustomer(this.formData.customerName);
    } else {
      this.searchCustomer('');
    }
  },
  methods: {
    getFileUrls(files) {
      const attachmentList = [];
      files.forEach((v) => {
        const data = {
          fileName: v.name,
          fileUrl: v.url,
        };
        attachmentList.push(data);
      });
      this.formData.fileVoList = attachmentList;
      this.$refs.form.validateField('fileVoList');
    },
    changeStatus(value) {
      if (value === 2) {
        this.formData.legalMobile = null;
        this.formData.legalName = null;
        this.formData.startDate = null;
        this.formData.legalIdNo = null;
        this.isDisabled = true;
      } else {
        this.load = false;
        setTimeout(() => {
          this.load = true;
        }, 10);
        this.isDisabled = false;
      }
    },

    searchCustomer(name) {
      this.ajax({
        type: 'POST',
        url: '/malicrm/business/selectCustomer',
        data: {
          customerName: name,
        },
        loading: false,
        success: (res) => {
          if (res.code === 200) {
            this.customerOptions = res.result;
          }
        },
      });
    },
    getCustomerList() {
      this.searchCustomer('');
    },
    changeCustomer() {
      this.formData.customerName = this.customerOptions.filter((v) => v.id === this.formData.customerId)[0].customerName;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        this.formData.startDate = formatTime(this.formData.startDate, 'YYYY-MM-DD');
        if (valid) {
          this.ajax({
            type: 'POST',
            url: '/malicrm/eqb/grant/saveEqbElectricContractGrant',
            data: this.formData,
            success: (res) => {
              if (res.code === 200) {
                this.successTip('保存成功');
                this.$emit('cancel', true);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        } else {
          this.errorTip('请填写所有带星号的必填项');
        }
      });
    },
    changeChinese(e, name) {
      let value = e.target.value;
      value = value.replace(/[^\u4e00-\u9fa5]/g, '');
      this.formData[name] = value;
    },
    changeIdNo(e, name) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d]/g, '');
      this.formData[name] = value;
    },
    changePhone(e, name) {
      let value = e.target.value;
      value = value.replace(/[^\d]/g, '');
      this.formData[name] = value;
    },
    changeAmount(e, name) {
      let value = e.target.value;
      value = value.replace(/[^\d.-]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value >= 1000) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 3);
        }
      }
      if (Number(value) === 0) {
        value = null;
      }
      this.formData[name] = value ? Number(value) : null;
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.after-req {
  &::after {
    margin-left: 4px;
    content: '*';
    color: #f04737;
  }
}
</style>
