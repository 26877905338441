
import { defineComponent, getCurrentInstance, reactive, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './useListData';
import Contracform from './form.vue';
export default defineComponent({
  name: 'electronContract',
  components: { ErpList, Contracform },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    const dialog = reactive({
      // 弹窗展示对象
      visible: false,
      type: 1,
      formType: 1,
      detailsData: null,
    });

    const closeDialog = (status) => {
      if (status) {
        dialog.visible = false;
        refreshTable(status);
      } else {
        dialog.visible = false;
      }
    };
    const addIndex = (type, formType) => {
      dialog.type = type;
      dialog.formType = formType;
      dialog.visible = true;
    };
    const getauditDetails = (row) => {
      dialog.type = 2;
      dialog.detailsData = row;
      dialog.visible = true;
    };
    // 查看详情
    const rowClick = () => {
      return true;
    };

    return {
      getauditDetails,
      closeDialog,
      dialog,
      ...toRefs(dialog),
      addIndex,
      columnList,
      tableRef,
      refreshTable,
      rowClick,
    };
  },
});
