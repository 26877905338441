import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!
  const _component_Contracform = _resolveComponent("Contracform")!
  const _component_erp_drawer = _resolveComponent("erp-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ErpList, {
      url: "/malicrm/eqb/grant/queryEqbElectricContractGrantListWithPage",
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "electronContract",
      onRowClick: _ctx.rowClick
    }, {
      header: _withCtx(() => [
        (_ctx.hasPermission('eqb:grant:saveEqbElectricContractGrant'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "erp-primary__btn erp-refresh ml-8",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addIndex(1, 1)))
            }, " 新增授权 "))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columOptions", "onRowClick"]),
    _createVNode(_component_erp_drawer, {
      visible: _ctx.dialog.visible,
      onClose: _ctx.closeDialog,
      erp: "",
      width: 960,
      class: "no-padding fix-top-header"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Contracform, {
          type: _ctx.dialog.type,
          baseData: _ctx.detailsData,
          onCancel: _ctx.closeDialog
        }, null, 8, ["type", "baseData", "onCancel"])
      ]),
      _: 1
    }, 8, ["visible", "onClose"])
  ], 64))
}